<template>
   <header class="mainPage-header lock-padding" id="mainPage-header">
      <div class="container">
         <div class="mainPage-header__row">
            <router-link to="/" class="mainPage-header__logo">
               <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                     d="M28.3806 9.27218H19.6084V0.5M28.3806 20.1084H19.6084V28.8806M0 9.27218H8.77218V0.5M0 20.1084H8.77218V28.8806"
                     stroke="white" stroke-width="3.61207" />
               </svg>
               <span>PROTENDER</span>
            </router-link>
            <ul class="mainPage-header__list">
               <!--             <li>
               <a @click="GoLinkRef('preferences')" class="mainPage-header__link">О нас</a>
            </li> -->
               <li>
                  <a @click="GoLinkRef('oportunity')" class="mainPage-header__link">
                     Наши возможности</a>
               </li>
               <li>
                  <a @click="GoLinkRef('subs')" class="mainPage-header__link">
                     Тарифы</a>
               </li>
               <!--             <li>
               <a @click="GoLinkRef('preim')" class="mainPage-header__link">
                  Преимущества</a>
            </li> -->
               <li>
                  <router-link to="/escort" class="mainPage-header__link">
                     Сопровождение</router-link>
               </li>
               <li>
                  <router-link to="/instructions" class="mainPage-header__link"
                     :class="{ 'active': $route.path == '/instructions' }">
                     Инструкции
                  </router-link>
               </li>
            </ul>
            <div class="mainPage-header__body header-body" :class="{ 'active': menumobile }">
               <div class="mainPage-header__menu mainPage-header-menu">
                  <div class="mainPage-header-menu__title">Меню</div>
                  <ul class="mainPage-header-menu__list">
                     <!--                   <li>
                     <a @click="GoLinkRef('preferences')" class="mainPage-header-menu__link">О нас</a>
                  </li> -->
                     <li>
                        <a @click="GoLinkRef('oportunity')" class="mainPage-header-menu__link">
                           Наши возможности</a>
                     </li>
                     <li>
                        <a @click="GoLinkRef('subs')" class="mainPage-header-menu__link">
                           Тарифы</a>
                     </li>
                     <!--                   <li>
                     <a @click="GoLinkRef('preim')" class="mainPage-header-menu__link">
                        Преимущества</a>
                  </li> -->
                     <li>
                        <router-link to="/escort" class="mainPage-header-menu__link">
                           Сопровождение</router-link>
                     </li>
                     <li>
                        <router-link to="/instructions" class="mainPage-header-menu__link"
                           :class="{ 'active': $route.path == '/instructions' }">
                           Инструкции
                        </router-link>
                     </li>
                  </ul>
                  <a v-if="!isAuthenticated" @click="OpenLogIn()" class="mainPage-header-menu__sign modal__link">Войти</a>
                  <a v-else @click="GoTo('/profile')" class="mainPage-header-menu__sign modal__link">Личный кабинет</a>
                  <a href="mailto:i@protender.pro" class="mainPage-header-menu__mail">
                     <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.6 18.5L17.37 10.73H0.75V8.27H17.37L9.6 0.499999H13.05L22.05 9.5L13.05 18.5H9.6Z"
                           fill="white" />
                     </svg>
                     <span>i@protender.pro</span>
                  </a>
               </div>
               <div class="mainPage-header__copy mainPage-header-copy">
                  <div class="mainPage-header-copy__item">© PROTENDER, 2023</div>
                  <a href="https://protender.pro/terms_of_use_protender" target="_blank"
                     class="mainPage-header-copy__link">Политика обработки персональных данных
                  </a>
                  <a href="https://protender.pro/privacy_policy_protender" target="_blank"
                     class="mainPage-header-copy__link">Пользовательское соглашение</a>
               </div>
            </div>
            <a v-if="!isAuthenticated" @click="OpenLogIn()" class="mainPage-header__sign modal__link">Войти</a>
            <a v-else @click="GoTo('/profile')" class="mainPage-header__sign modal__link">Личный кабинет</a>
            <div class="mainPage-header__burger burger-btn" :class="{ 'active': menumobile, 'not-visible': openModal }" @click="openMobileMenu()">
               <button><span></span></button>
               <span class="show">Меню</span>
               <span class="close">Закрыть</span>
            </div>
         </div>
      </div>
   </header>

   <auth-modal :openLog="openLog" v-on:close="openLog = $event" :openModal="openModal" v-on:close-modal="openModal = $event" ></auth-modal>
</template>

<style scoped></style>

<script>
import Modal from '@/components/Auth/Modal'
import axios from 'axios'
export default {
   name: 'Header',
   data() {
      return {
         menumobile: false,
         status: false,
         openLog: false,
         openModal: false,
         isAuthenticated: false,
      }
   },
   props: {
      user__profile: {
         type: Boolean,
         default: false
      },
      open_tarif: {
         type: Boolean,
         default: false
      },
   },
   mounted() {
      this.isAuthenticated = this.$store.state.isAuthenticated
   },
   methods: {
      GoTo(value) {
         this.$router.push(value)
      },
      OpenLogIn() {
         this.openLog = true
         this.openModal = true
      },
      openMobileMenu() {
         this.menumobile = !this.menumobile
      },
      GoLinkRef(value) {
         this.$router.push({ path: '/', query: { redirect: value } })
         this.menumobile = false
      },
      logout() {
         axios.defaults.headers.common["Authorization"] = ""

         localStorage.removeItem("token")
         localStorage.removeItem("username")
         localStorage.removeItem("userid")
         localStorage.removeItem("sub_id")
         localStorage.removeItem("id_pay")
         localStorage.removeItem("user")
         localStorage.removeItem("status_pay")
         localStorage.removeItem("anketaCheck")

         this.$store.commit('removeUser')
         this.$store.commit('removeUserInfo')
         this.$store.commit('removeToken')
         this.$store.commit('removeSub')
         this.$store.commit('removePayID')
         this.$store.commit('removePayStatus')

         this.$router.push('/sign-up')
      },
   },
   components: {
      'auth-modal': Modal,
   },
   watch: {
      open_tarif(value) {
         this.openLog = value
         console.log(this.openLog)
      },
   },
}
</script>