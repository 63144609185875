<template>
   <div class="authorization" v-if="openModal">
      <div class="promocode modal authorization__modal" @mousedown="onMouseDownHandler" @mouseup="onMouseUpHandler"
         :class="{ open: openLog }" id="promocode">
         <div class="promocode__content modal__content authorization__content" @click.stop="onClickContentHandler">
            <div class="promocode__row authorization__row">
               <div class="promocode__title authorization__title">
                  Регистрация
               </div>
               <a @click="closePopup" class="promocode__close modal__close authorization__close"></a>
            </div>
            <div class="promocode__main">
               <div class="promocode__input authorization__input">
                  <span>Введите промокод</span>
                  <input v-model="promo" type="text" />
                  <span class="alert_promo-text" v-for="item in alertsPromo" :key="item">{{ item }}</span>
               </div>
               <div class="promocode__buttons">
                  <a @click="nextTip('register', true)" class="promocode__btn btn gold modal__link">Продолжить</a>
                  <a @click="nextTip('register', true)" class="promocode__btn btn grey modal__link">Продолжить без
                     промокода</a>
               </div>
            </div>
            <a @click="LogIn('sign', true)" class="promocode__already authorization__link modal__link">Уже есть
               аккаунт?</a>
         </div>
      </div>
      <div class="register modal authorization__modal" id="register" @mousedown="onMouseDownHandler"
         @mouseup="onMouseUpHandler" :class="{ open: register }">
         <div class="register__content modal__content authorization__content" @click.stop>
            <div class="register__row authorization__row">
               <div class="register__title authorization__title">
                  Регистрация
               </div>
               <a @click="closeRegister" class="register__close modal__close authorization__close"></a>
            </div>
            <div class="register__main">
               <form @submit.prevent="submitForm()" class="register__data">
                  <div class="register__input authorization__input">
                     <span>ФИО</span>
                     <input v-model="fio" type="text" />
                  </div>
                  <div class="register__input authorization__input">
                     <span>Телефон</span>
                     <input v-model="phone" type="tel" v-mask="'+# (###) ###-##-##'" />
                  </div>
                  <div class="register__input authorization__input">
                     <span>ИНН</span>
                     <input v-model="inn" type="tel" v-mask="'##################'" />
                  </div>
                  <div class="register__input authorization__input">
                     <span>E-mail</span>
                     <input v-model="email" type="email" />
                  </div>
                  <div class="register__input authorization__input show-pass__wrapper">
                     <!-- пароль -->
                     <span>Пароль</span>
                     <input v-model="pas1" :type="is_show_pas1 ? 'text' : 'password'" />
                     <button class="show-pass" @click.prevent="is_show_pas1 = !is_show_pas1">
                        <img v-if="!is_show_pas1" src="@/assets/img/icons/eye.svg" alt="">
                        <img v-else src="@/assets/img/icons/eye-off.svg" alt="">
                     </button>
                  </div>
                  <div class="register__input authorization__input show-pass__wrapper">
                     <!-- пароль -->

                     <span>Пароль еще раз</span>
                     <input v-model="pas2" :type="is_show_pas2 ? 'text' : 'password'" />
                     <button class="show-pass" @click.prevent="is_show_pas2 = !is_show_pas2">
                        <img v-if="!is_show_pas2" src="@/assets/img/icons/eye.svg" alt="">
                        <img v-else src="@/assets/img/icons/eye-off.svg" alt="">
                     </button>
                  </div>
                  <span class="alert_promo-text" v-for="item in errors" :key="item">{{ item }}</span>
               </form>
               <div class="register__selected register-selected">
                  <div class="promocode__input authorization__input">
                     <span>Введите промокод</span>
                     <input v-model="promo" type="text" />
                     <span class="alert_promo-text" v-for="item in alertsPromo" :key="item">{{ item }}</span>
                  </div>

                  <div class="card__tags">
                     <h3 class="select__sub-title" v-for="(item, index) in subs" :class="{ active: tag_cur == index }"
                        @click="chooseSub(item.id, index)" :key="item.price">
                        <span>{{ DateSort(item.duration) }}</span>
                        <span class="discount-promo_color" v-if="current_price != 0 && crtarif == item.id">
                           {{ numberWithSpaces(current_price) }}₽
                        </span>
                        <span v-else>
                           {{ numberWithSpaces(item.price) }} ₽
                        </span>
                     </h3>
                  </div>
                  <div class="sub__block" v-if="sub.id">
                     <div class="register-selected__info">
                        <div class="register-selected__title">
                           Выбранный тариф
                        </div>
                        <div class="register-selected__data">
                           {{ DateSort(sub.duration) }}
                        </div>
                        <div class="register-selected__price" v-if="current_price != 0 && crtarif == sub.id">
                           {{ numberWithSpaces(current_price) }}₽
                           <s>{{ numberWithSpaces(sub.price) }} ₽</s>
                        </div>
                        <div class="register-selected__price" v-else>
                           {{ numberWithSpaces(sub.price) }} ₽
                        </div>
                     </div>
                     <div class="register-selected__row">
                        <div class="register-selected__pref">
                           <p>
                              Количество запросов
                              <span v-if="sub.request_num == 9999">не ограничено</span>
                              <span v-else>{{ sub.request_num }}</span>
                           </p>
                        </div>
                        <div class="register-selected__pref">
                           <p>{{ sub.ustr }}</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="register__buttons">
               <a v-if="fio == '' || phone == '' || inn == ''" @click="ViewErrors()"
                  class="register__btn btn gold disb">Перейти к оплате</a>
               <a v-else @click="submitForm()" class="register__btn btn gold">Перейти к оплате</a>
               <a @click="LogIn('sign', true)" class="register__already authorization__link modal__link">Уже есть
                  аккаунт?</a>
            </div>
            <p class="register-modal__remind" v-if="current_price == 1">
               После оплаты 1 ₽ вернется на карту
            </p>
         </div>
      </div>
      <div class="sign modal authorization__modal" id="sign" :class="{ open: sign }" @mousedown="onMouseDownHandler"
         @mouseup="onMouseUpHandler">
         <form @submit.prevent="LogInForm()" class="sign__content modal__content authorization__content" @click.stop>
            <div class="sign__row authorization__row">
               <div class="sign__title authorization__title">Вход</div>
               <a @click="closeLog" class="sign__close modal__close authorization__close"></a>
            </div>
            <div class="sign__main">
               <div class="sign__input authorization__input">
                  <span>E-mail</span>
                  <input v-model="email_log" type="email" />
               </div>
               <div class="sign__input authorization__input show-pass__wrapper">
                  <span>Пароль</span>
                  <input v-model="pass_login" name="password" :type="is_show_pass_login ? 'text' : 'password'" />
                  <button class="show-pass" @click.prevent="is_show_pass_login = !is_show_pass_login">
                     <img v-if="!is_show_pass_login" src="@/assets/img/icons/eye.svg" alt="">
                     <img v-else src="@/assets/img/icons/eye-off.svg" alt="">
                  </button>
               </div>
               <span class="alert_promo-text" v-for="item in errors_login" :key="item">{{ item }}</span>
               <a @click="nextTip('forget1', true)" class="sign__forget modal__link">Не помню пароль</a>
            </div>
            <div class="sign__buttons">
               <button type="submit" class="sign__btn btn gold">Войти</button>
               <a @click="nextTip('register', true)" class="sign__btn btn grey modal__link">Зарегистрироваться</a>
            </div>
         </form>
      </div>
      <div class="forget1 modal authorization__modal" id="forget1" :class="{ open: forget1 }"
         @mousedown="onMouseDownHandler" @mouseup="onMouseUpHandler">
         <div class="forget1__content modal__content authorization__content" @click.stop>
            <div class="forget1__row authorization__row">
               <div class="forget1__title authorization__title">
                  Восстановление пароля
               </div>
               <a @click="closeForget" class="forget1__close modal__close authorization__close"></a>
            </div>
            <div class="forget1__text">
               Мы отправим вам на E-mail ссылку на восстановление пароля
            </div>
            <div class="forget1__main">
               <div class="forget1__input authorization__input">
                  <span>E-mail, к которому привязан аккаунт</span>
                  <input v-model="reset_email" type="email" />
               </div>
               <div class="forget1__buttons">
                  <a @click="RestorePassword(reset_email)" class="sign__btn btn gold modal__link">Продолжить</a>
                  <a @click="nextTip('forget1', false)" class="sign__btn btn grey modal__close">Отмена</a>
               </div>
            </div>
         </div>
      </div>
      <div class="forger2 modal authorization__modal" id="forget2" :class="{ open: forget2 }"
         @mousedown="onMouseDownHandler" @mouseup="onMouseUpHandler">
         <div class="forget2__content modal__content authorization__content" @click.stop>
            <div class="forget2__row authorization__row">
               <div class="forget1__title authorization__title">
                  Восстановление пароля
               </div>
               <a @click="nextTip('forget2', false)" class="forget1__close modal__close authorization__close"></a>
            </div>
            <div class="forget2__text">
               Запрос на восстановление пароля отправлен на почту
               <span>{{ reset_email }}</span>
            </div>
            <a @click="nextTip('forget2', false)" class="sign__btn btn gold modal__close">Закрыть</a>
         </div>
      </div>
   </div>
</template>

<style scoped>
.alert_promo-text {
   color: #ffd954;
   font-size: 14px;
   margin: 4px 0 0;
}

.discount-promo_color {
   color: #ffd954;
}

/*.alert_promo-text.error {
    color: #ffd954;
}*/

.disb {
   opacity: 0.7 !important;
}

.register-modal__remind {
   color: rgba(255, 255, 255, 0.7);
   margin-top: -20px;
}
</style>

<script>
import { mask } from "vue-the-mask";
import axios from "axios";
import moment from "moment";

import { toast } from "bulma-toast";

export default {
   name: "Modal",
   directives: { mask },
   data() {
      return {
         fio: "",
         phone: "",
         inn: "",
         email: "",
         pas1: "",
         pas2: "",
         sub_id: 0,

         errors: [],
         errors_login: [],

         id_new_user: 0,
         current_price: 0,

         user_account: {},

         email_log: "",
         pass_login: "",

         user_account: {},
         isAuthenticated: false,

         menumobile: false,
         register: false,
         sign: false,
         forget1: false,
         forget2: false,

         subs: [],

         sub: {},

         tag_cur: 0,

         reset_email: "",

         promo: "",

         alertsPromo: [],

         crtarif: 0,

         current_price: 0,

         mouseTarget: null,





         is_show_pass_login: false,
         is_show_pas1: false,
         is_show_pas2: false
      };
   },
   props: {
      openLog: {
         type: Boolean,
         default: false,
      },
      openModal: {
         type: Boolean,
         default: false,
      },
   },
   methods: {
      closeLog() {
         this.nextTip('sign', false)
         this.$emit("closeModal", false);
         this.clearAlertsAndErrosMessages()
      },
      closeRegister() {
         console.log('closeRegister');
         this.register = false
         this.$emit("closeModal", false);
         this.clearAlertsAndErrosMessages()
      },
      closeForget() {
         this.nextTip('forget1', false)
         this.$emit("closeModal", false);
         this.clearAlertsAndErrosMessages()
      },
      nextTip(value, boll) {
         this[value] = boll;
         console.log('emit nextTip');
         this.$emit("close", false);

         if (value == "register") this.sign = false;
         if (value == "forget1") (this.sign = false), (this.register = false);
         if (value == "forget2") this.forget1 = false;
      },
      LogIn(value, boll) {
         this[value] = boll;

         this.register = false;
         this.$emit("close", false);
         this.clearAlertsAndErrosMessages()
      },
      async GetSubs() {
         await axios
            .get("/api/v1/subs/")
            .then((response) => {
               this.subs = response.data;
               this.GetSubUser(response.data[0].id);
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async PromoUsed(value) {
         await axios
            .get(`/api/v1/promo/used/${value}/`)
            .then((response) => {
               console.log(response.data);
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async CheckPromo() {
         this.prom = {};

         await axios
            .get(`/api/v1/promocode/${this.promo}/`)
            .then((response) => {
               console.log("asdsadsadsada: ", response.data.body.sub);
               // this.tag_cur = response.data.body.sub - 1;
               this.chooseSub(
                  response.data.body.sub,
                  response.data.body.sub - 1
               );
               this.prom = response.data.body;
               this.alertsPromo = [];

               if (Number(response.data.request_num) == 0) {
                  this.error_promo.push("Срок действия промокода закончился.");
               }
               if (response.data.status == "none") {
                  this.alertsPromo.push("Промокода не существует.");
               } else {
                  if (Number(response.data.body.request_num) == 0)
                     this.alertsPromo.push(
                        "Срок действия промокода закончился."
                     );
                  else {
                     this.alertsPromo.push("Промокод успешно активирован.");
                     this.crtarif = response.data.body.sub;
                     var objsub = this.subs.find(
                        (item) => item.id === this.crtarif
                     );

                     if (response.data.body.percent == 100)
                        this.current_price = 1;
                     else
                        this.current_price =
                           (parseInt(objsub.price) *
                              (100 - response.data.body.percent)) /
                           100;

                     console.log(this.current_price);
                  }
               }
            })
            .catch((error) => {
               this.alertsPromo.push("Ошибка.");
            });
      },
      async LogInForm() {
         this.errors_login = [];

         axios.defaults.headers.common["Authorization"] = "";
         localStorage.removeItem("token");

         const formData = {
            username: this.email_log,
            password: this.pass_login,
         };

         await axios
            .post("/api/v1/token/login/", formData)
            .then((response) => {
               const token = response.data.auth_token;

               this.$store.commit("setToken", token);

               axios.defaults.headers.common["Authorization"] =
                  "Token " + token;

               localStorage.setItem("token", token);

               this.$store.commit("setUser", this.user_account.id);

               localStorage.setItem("user_id", this.user_account.id);

               localStorage.setItem("user", this.user_account);

               this.$store.commit("setUserInfo", this.user_account);

               this.$router.push("/profile");
            })
            .catch((error) => {
               if (error.response) {
                  for (const property in error.response.data) {
                     console.log(property);
                     if (String(property) == "non_field_errors") {
                        this.errors_login.push("Неправильный логин или пароль");
                     } else {
                        this.errors_login.push(
                           `${error.response.data[property]}`
                        );
                     }
                  }
               } else {
                  this.errors_login.push("Что-то пошло не так...");
               }
            });
      },
      async submitForm() {
         this.errors = [];

         if (this.pas1 != this.pas2) {
            this.errors.push("Пароли не одинаковые.");
         }

         if (this.validEmail(this.email) == false) {
            this.errors.push("Почта некорректна.");
         }

         if (!this.errors.length) {
            const formData = {
               username: this.email,
               password: this.pas1,
               email: this.email,
            };

            await axios
               .post("/api/v1/users/", formData)
               .then((response) => {
                  this.id_new_user = response.data.id;
               })
               .catch((error) => {
                  if (error.response) {
                     for (const property in error.response.data) {
                        if (String(property) == "username") {
                           this.errors.push(
                              "Такой пользователь уже зарегистрирован"
                           );
                        } else {
                           this.errors.push(`${error.response.data[property]}`);
                        }
                     }
                  } else if (error.message) {
                     this.errors.push("Попробуйте еще раз, произошла ошибка");
                  }
               });

            if (this.id_new_user != 0) {
               const UserData = {
                  user: this.id_new_user,
                  fio: this.fio,
                  email: this.email,
                  phone: this.phone,
                  inn: this.inn,
                  sub: this.sub_id,
                  payment: false,
                  // ----------------------------------------------------------
               };

               await axios
                  .post("/api/v1/myProfiles/", UserData)
                  .then((response) => {
                     this.user_account = response.data;
                  })
                  .catch((error) => {
                     console.log(error);
                  });

               axios.defaults.headers.common["Authorization"] = "";

               localStorage.removeItem("token");

               await axios
                  .post("/api/v1/token/login/", formData)
                  .then((response) => {
                     const token = response.data.auth_token;

                     // this.SendEmailReg(this.user_account.email)

                     this.$store.commit("setToken", token);

                     axios.defaults.headers.common["Authorization"] =
                        "Token " + token;

                     localStorage.setItem("token", token);

                     this.$store.commit("setUser", this.user_account.id);

                     localStorage.setItem("user_id", this.user_account.id);

                     localStorage.setItem("user", this.user_account);

                     this.$store.commit("setUserInfo", this.user_account);

                     this.PaymentSend(this.sub);
                  })
                  .catch((error) => {
                     console.log(error.response);
                     if (error.response) {
                        for (const property in error.response.data) {
                           this.errors.push(
                              `${property}: ${error.response.data[property]}`
                           );
                        }
                        console.log(error.response);
                        console.log(JSON.stringify(error));
                     } else {
                        this.errors.push("Что-то пошло не так...");

                        console.log(JSON.stringify(error));
                     }
                  });
            }
         }
      },
      async PaymentSend(item) {
         console.log("payment start");
         const paymentData = {};
         paymentData.email = this.email;
         paymentData.indificator = this.sub.indificator;
         if (this.current_price != 0 && this.crtarif == item.id)
            paymentData.price = this.current_price;
         else paymentData.price = item.price;

         await axios
            .post("/api/v1/post/payment/", paymentData)
            .then((response) => {
               console.log("payment response");
               if (this.current_price != 0) {
                  console.log("payment current price != 0");
                  this.PromoUsed(this.promo);
               }
               if (response.data?.status == "error") {
                  console.log("payment status == error");
                  toast({
                     message: "Ошибка регистрации",
                     type: "is-success",
                     dismissible: true,
                     pauseOnHover: true,
                     duration: 3000,
                     position: "bottom-right",
                  });

                  return;
               }
               window.location.href = response.data;
            })
            .catch((error) => {
               console.log(error);
            });
      },
      validEmail(email) {
         var re =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
         return re.test(email);
      },
      ViewErrors() {
         this.errors = [];

         if (this.fio.length == 0) this.errors.push("Поле Имя не заполнено.");

         if (this.phone.length == 0)
            this.errors.push("Поле Телефон не заполнено.");

         if (this.inn.length == 0) this.errors.push("Поле ИНН не заполнено.");

         if (this.pas1 != this.pas2) this.errors.push("Пароли не одинаковые.");
      },
      chooseSub(id, index) {
         this.tag_cur = index;

         this.GetSubUser(id);
      },
      numberWithSpaces(x) {
         return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      async GetSubUser(value) {
         await axios
            .get(`/api/v1/get/sub/${value}/`)
            .then((response) => {
               this.sub = response.data;
               console.log(response.data);
               this.sub_id = response.data.id;
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async RestorePassword(value) {
         await axios
            .post(`/api/v1/users/reset_password/`, { email: value })
            .then((response) => {
               console.log(response.data);
               this.nextTip("forget2", true);
            })
            .catch((error) => {
               console.log(error);
            });
      },
      DateSort(value) {
         const item = Math.round(moment.duration(value, "days").asMonths());
         var result = item + " " + "месяцев";
         if (item / 12 == 1) result = "1 год";
         if (item == 3) result = item + " " + "месяца";
         if (item == 1) result = item + " " + "месяц";
         return result;
      },
      closePopup(e) {
         // console.log(e);
         console.log('emit close closePopup');
         this.$emit("close", false);
         this.$emit("closeModal", false);
         this.clearAlertsAndErrosMessages()
      },
      onClickContentHandler(e) {
         // console.log(e);
      },
      onMouseDownHandler(e) {
         this.mouseTarget = e.target;
      },
      onMouseUpHandler(e) {
         if (
            e.target == this.mouseTarget &&
            e.target.classList.contains("modal")
         ) {
            console.log('emit close onMouseUpHandler');
            this.$emit("close", false);
            this.$emit("closeModal", false);
            this.clearAlertsAndErrosMessages()

         } else {
            this.mouseTarget = null;
         }
      },
      clearAlertsAndErrosMessages() {
         if (this.alertsPromo.length) {
            this.alertsPromo = []
         }

         if (this.errors.length) {
            this.errors = []
         }

         if (this.errors_login.length) {
            this.errors_login = []
         }
      }
   },
   watch: {
      promo(value) {
         this.promo = value;
         this.current_price = 0;

         if (this.promo == "none") {
            this.promo = "";
         }

         if (this.promo.length > 3) {
            this.CheckPromo();
         }
      },
   },
   created() {
      this.GetSubs();
   },
};
</script>
