<template>
   <profile-head></profile-head>
   <main class="main">
      <section class="hero">
         <div class="container">
            <div class="hero__row">
               <div class="name card tender-detail">
                  <header class="name-header">
                     <div class="name-header__info">
                        <ul class="name-header__list" v-if="tender.Etp">
                           <li class="white">
                              <p>{{ tender.Etp }}</p>
                           </li>
                           <li class="blue" v-if="tender.Fz">
                              {{ tender.Fz }}-ФЗ
                           </li>
                           <!-- <li class="gold">АУК</li> -->
                        </ul>
                        <div class="name-header__name" v-if="tender.TenderNumOuter && tender.Date">
                           <span>Тендер №{{ tender.TenderNumOuter }}
                              <span class="dark">от {{ CorDate(tender?.Date) }}</span></span>
                        </div>
                     </div>
                     <ul class="name-header__actions">
                        <!--                         <li class="name-header__hide">
                           <span>Скрыть</span>
                        </li> -->
                        <li class="name-header__favor" :class="{ favor__active: status == 'success' }">
                           <span v-if="status == 'error'" @click="favOpen()">В избранное</span>
                           <span v-else @click="UnFav(id)">Убрать из избранного</span>
                           <button v-if="status == 'error'" @click="favOpen()"></button>
                           <ul class="tender-footer__sublist" v-if="favBlock" :class="{ show: favBlock }">
                              <li v-for="item in favObjects" :key="item" @click="AddFavorite(tender, item.id)">
                                 {{ item.name }}
                              </li>
                           </ul>
                        </li>
                     </ul>
                  </header>
                  <main class="name-main">
                     <p class="name-main__text" v-if="tender.TenderName">
                        {{ tender.TenderName }}
                     </p>
                  </main>
                  <footer class="name-footer">
                     <div class="name-footer__text" v-if="tender.EndTime">
                        <span>Завершение приема заявок (МСК)
                           <span class="dark">{{
                              CorDate(tender.EndTime)
                           }}</span></span>
                     </div>
                     <ul class="name-header__actions name-footer__actions">
                        <!--                         <li class="name-header__hide">
                           <span>Скрыть</span>
                        </li> -->
                        <li class="name-header__favor" :class="{ favor__active: status == 'success' }">
                           <span v-if="status == 'error'" @click="favOpen()">В избранное</span>
                           <span v-else @click="UnFav(id)">Убрать из избранного</span>
                           <button v-if="status == 'error'" @click="favOpen()"></button>
                           <ul class="tender-footer__sublist" v-if="favBlock" :class="{ show: favBlock }">
                              <li v-for="item in favObjects" :key="item" @click="AddFavorite(tender, item.id)">
                                 {{ item.name }}
                              </li>
                           </ul>
                        </li>
                     </ul>
                  </footer>
               </div>
               <div class="remind card">
                  <header class="remind__header">
                     До окончания приёма заявок осталось
                  </header>
                  <main class="remind__main" v-if="dateEnd">
                     <ul class="remind__list">
                        <li>
                           <span class="big">{{ dateEnd }}</span>
                           <span class="small">дн</span>
                        </li>
                        <li>
                           <span class="big">00</span>
                           <span class="small">ч</span>
                        </li>
                        <li>
                           <span class="big">00</span>
                           <span class="small">мин</span>
                        </li>
                     </ul>
                  </main>
                  <footer class="remind__footer">
                     <a v-if="
                        tender.TenderLinkEtp ||
                        tender.TenderLink ||
                        tender.EisLink
                     " :href="tender.TenderLink
                        ? tender.TenderLink
                        : tender.TenderLinkEtp || tender.EisLink
                        " target="_blank" class="remind__transition btn remind__btn">
                        Перейти на площадку
                     </a>
                     <router-link :to="{
                        path: '/profile/escort?modal=true',
                        query: {
                           modal: true,
                           tender: id,
                        },
                        params: {
                           tender: id,
                        },
                     }" target="_blank" class="remind__help btn remind__btn">Помощь в участии</router-link>
                  </footer>
               </div>
            </div>
         </div>
      </section>
      <section class="info">
         <div class="container">
            <div class="info__row">
               <div class="info__column">
                  <div class="basic info__card">
                     <div class="info__title basic__title subtitle">
                        Основное
                     </div>
                     <div class="info__main basic__main">
                        <div class="basic__item info__text dark">
                           Начальная цена
                        </div>
                        <div class="basic__item info__text light" v-if="tender.Price">
                           {{ numberWithSpaces(tender.Price) }} ₽
                        </div>
                        <div class="basic__item info__text light" v-else>—</div>
                        <div class="basic__item info__text dark">
                           Место поставки
                        </div>
                        <div class="basic__item info__text light" v-if="tender.Region">
                           {{ tender.Region }}
                        </div>
                        <div class="basic__item info__text light" v-else>—</div>
                        <div class="basic__item info__text dark">Отрасль</div>
                        <div class="basic__item info__text light" v-if="tender.Category">
                           <ul class="basic__list">
                              <li>{{ tender.Category }}</li>
                              <!-- <li>Расходные материалы к офисному оборудованию</li> -->
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div class="client info__card">
                     <div class="info__title client__title subtitle">
                        Заказчик
                     </div>
                     <div class="info__main client__main">
                        <div class="client__item info__text dark">
                           Наименование
                        </div>
                        <div class="client__item info__text light" v-if="tender.Customer" v-html="tender.Customer">
                        </div>
                        <div class="client__item info__text light" v-else>
                           —
                        </div>
                        <div class="client__item info__text dark">ИНН</div>
                        <div class="client__item info__text light" v-if="tender.CustomerINN">
                           {{ tender.CustomerINN }}
                        </div>
                        <div class="client__item info__text light" v-else>
                           —
                        </div>
                        <div class="client__item info__text dark"></div>
                        <div class="client__item info__text light"></div>
                        <router-link :to="'/profile/contragent/' + tender.CustomerINN" v-if="tender.CustomerINN"
                           class="client__btn info__text light">Анализ заказчика</router-link>
                     </div>
                  </div>
                  <div class="responsible info__card">
                     <div class="info__title responsible__title subtitle">
                        Ответственный
                     </div>
                     <div class="info__main responsible__main">
                        <div class="responsible__item info__text dark">ФИО</div>
                        <div class="responsible__item info__text light" v-if="tender.contactName">
                           {{ tender.contactName }}
                        </div>
                        <div class="responsible__item info__text light" v-else>
                           —
                        </div>
                        <div class="responsible__item info__text dark">
                           Телефон
                        </div>
                        <div class="responsible__item info__text light" v-if="tender.contactPhone">
                           {{ tender.contactPhone }}
                        </div>
                        <div class="responsible__item info__text light" v-else>
                           —
                        </div>
                        <div class="responsible__item info__text dark">
                           E-mail
                        </div>
                        <div class="responsible__item info__text light" v-if="tender.contactEmail">
                           {{ tender.contactEmail }}
                        </div>
                        <div class="responsible__item info__text light" v-else>
                           —
                        </div>
                     </div>
                  </div>
                  <div class="documents info__card">
                     <div class="documents__title info__title subtitle">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                           <path d="M9.625 7.29175H18.375V9.04175H9.625V7.29175Z" fill="#FFD954" />
                           <path d="M18.375 11.9583H9.625V13.7083H18.375V11.9583Z" fill="#FFD954" />
                           <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M23.0418 2.625H4.9585V25.375H23.0418V2.625ZM21.2918 18.9583V4.375H6.7085V18.9583H21.2918ZM6.7085 20.7083V23.625H21.2918V20.7083H6.7085Z"
                              fill="#FFD954" />
                        </svg>
                        <span>Документы</span>
                     </div>

                     <ul class="documents__list" v-if="
                        tender?.docsXML &&
                        Object.keys(tender?.docsXML).length
                     ">
                        <li>
                           <button class="documents__load documents__load_all" @click="downloadAll">
                              Скачать все
                           </button>
                        </li>
                        <template v-for="item in tender?.docsXML?.document" :key="item">
                           <li class="documents__item" v-if="Array.isArray(tender?.docsXML?.document)">
                              <div class="documents__subtitle">
                                 {{ item.name }}
                              </div>
                              <a download :href="item.link" class="documents__load">Скачать</a>
                           </li>
                        </template>

                        <li class="documents__item" v-if="tender?.docsXML?.document?.link">
                           <div class="documents__subtitle">
                              {{ tender?.docsXML?.document.name }}
                           </div>
                           <a download :href="tender?.docsXML?.document.link" class="documents__load">Скачать</a>
                        </li>
                     </ul>
                     <div class="documents__empty" v-else>-</div>
                  </div>
               </div>
               <div class="info__column">
                  <div class="part info__card">
                     <div class="info__title part__title subtitle">Участие</div>
                     <div class="info__main part__main">
                        <div class="part__item info__text dark">
                           Способ размещения
                        </div>
                        <div class="part__item info__text light" v-if="tender.TenderType">
                           {{ tender.TenderType }}
                        </div>
                        <div class="part__item info__text light" v-else>—</div>
                        <div class="part__item info__text dark">
                           Подача заявок (МСК)
                        </div>
                        <div class="part__item info__text light" v-if="tender.Date && tender.EndTime">
                           {{ CorDate(tender.Date) }} —
                           {{ CorDate(tender.EndTime) }}
                        </div>
                        <div class="part__item info__text light" v-else>—</div>
                        <!--                         <div class="part__item info__text dark part__item_fit">
                           Подача ценовых предложений (МСК)
                        </div>
                        <div class="part__item info__text light">
                           15.04.2023 — 20.04.2023 10:00
                        </div> -->
                        <div class="part__item info__text dark">
                           Подведение итогов (МСК)
                        </div>
                        <div class="part__item info__text light" v-if="tender.EndTime">
                           {{ CorDate(tender.EndTime) }}
                        </div>
                        <div class="part__item info__text light" v-else>—</div>
                     </div>
                  </div>
                  <div class="require info__card">
                     <div class="info__title require__title subtitle">
                        Требования и преимущества
                     </div>
                     <div class="info__main require__main">
                        <div class="require__item info__text dark">
                           Обеспечение заявки
                        </div>
                        <div class="require__item info__text light" v-if="tender.ObPrice">
                           {{ numberWithSpaces(tender.ObPrice) }} ₽
                        </div>
                        <div class="require__item info__text light" v-else>
                           —
                        </div>
                        <div class="require__item info__text dark">
                           Обеспечение контракта
                        </div>
                        <div class="require__item info__text light" v-if="tender.ObPriceZ">
                           {{ tender.ObPriceZ }}
                        </div>
                        <div class="require__item info__text light" v-else>
                           —
                        </div>
                        <!--                         <div class="require__item info__text dark">
                           Требования к участникам
                        </div>
                        <div class="require__item info__text light">
                           <ul class="require__list">
                              <li v-if="tender.TenderType">
                                 {{ tender.TenderType }}
                              </li>
                              <li v-else>
                                -  
                              </li>
                           </ul>
                        </div> -->
                        <!--                         <div class="require__item info__text dark">
                           Ограничения и запреты
                        </div>
                        <div class="require__item info__text light">
                           Запрет на допуск товаров, работ, услуг при осуществлении закупок, а также ограничений и
                           условия допуска в соответствии с требованиями, установленными ст. 14 Закона № 44-ФЗ
                        </div> -->
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="members" v-if="members.length">
         <div class="container">
            <div class="members__card">
               <div class="members__title subtitle">
                  Потенциальные участники
               </div>
               <div class="members__column">
                  <div class="members__grid info__text light">
                     <div class="members__name members__category">
                        Название участника
                     </div>
                     <div class="members__part members__category">
                     </div>
                     <div class="members__win members__category">Победил</div>

                     <div class="members__decline members__category">
                        Снижение цены
                     </div>
                     <div class="members__price members__category" style="grid-column: span 2;">
                        Вероятная цена, ₽
                     </div>
                  </div>
                  <div v-for="(member, index) in members" :key="index" class="members__grid member info__text light">
                     <div class="member__name" v-if="member.name" v-html="member.name"></div>
                     <div class="member__name" v-else>-</div>

                     <div class="member__part">

                     </div>
                     <div class="member__win">
                        <span>{{ member.contracts_count }}</span>
                     </div>
                     <div class="member__decline">{{
                        Math.ceil(member.average_drop_percentage) }}%</div>
                     <div class="member__price" style="grid-column: span 2;">
                        {{ numberWithSpaces(Math.ceil(member.predicted_price)) }} ₽
                     </div>
                  </div>
               </div>
               <div class="members__grid adapt">
                  <div v-for="(member, index) in members" class="member" :key="index">
                     <div class="member__title info__text light" v-if="member.name" v-html="member.name"></div>
                     <div class="member__title info__text light" v-else>-</div>
                     <ul class="member__list">
                        <!-- <li>
                           <span class="info__text light">Участвовал:</span>
                           <span class="info__text dark">
                              <span v-if="member.Lot == 1">Участвовал</span>
                              <span v-else>Не участвовал</span>
                           </span>
                        </li> -->
                        <li style="margin-top: 8px;">
                           <span class="info__text dark">Победил:</span>
                           <span class="info__text light member__win" style="padding: 0;">
                              <span>{{ member.contracts_count }}</span>
                           </span>
                        </li>
                        <!-- <li>
                           <span class="info__text light">Контракт:</span>
                           <span class="info__text dark">{{
                              member.Category
                           }}</span>
                        </li> -->
                        <li>
                           <span class="info__text dark">Снижение цены:</span>
                           <span class="info__text light ">{{
                              Math.ceil(member.average_drop_percentage) }}%</span>
                        </li>
                        <li>
                           <span class="info__text dark">Вероятная цена, ₽:</span>
                           <span class="info__text light ">{{ numberWithSpaces(Math.ceil(member.predicted_price)) }}
                              ₽</span>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section class="protect">
         <div class="container">
            <div class="protect__card">
               <main class="protect__main">
                  <div class="protect__title">
                     Защитите свой бизнес от рисков с тендерным сопровождением
                  </div>
                  <div class="protect__text tender__text">
                     Мы поможем вам подготовить документы, оценить риски и
                     предоставим полное юридическое сопровождение на всех этапах
                     участия в тендере
                  </div>
               </main>
               <footer class="protect__footer">
                  <!-- <router-link target="_blank" :to="'/profile/escort?modal=true&tender=' + id"
                     class="protect__btn btn tender__text">Подключить сопровождение</router-link> -->
                  <button @click="escrotForm = true" class="protect__btn btn tender__text">Подключить
                     сопровождение</button>
                  <router-link target="_blank" to="/profile/escort" class="protect__learn tender__text">Узнать
                     больше</router-link>
               </footer>
            </div>
         </div>
         <EscortModal :modal="escrotForm" :userOn="true" @close="escrotForm = $event" tender />
      </section>
   </main>
</template>

<style type="scss">
.tender-detail .tender-footer__sublist li:last-child::before {
   background: unset !important;
}

.documents__load_all {
   background-color: transparent;
   text-align: left;
}

.tenderPage .name-header__favor {
   position: relative;
}

.tenderPage .tender-footer__sublist.show {
   transform: translateY(50px) !important;
}

.tenderPage .member__name {
   text-transform: uppercase;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   margin-right: 49px;
}

@media (max-width: 576px) {
   .tenderPage .tender-footer__sublist.show {
      transform: translate(85px, 50px) !important;
      z-index: 10;
   }
}
</style>

<script>
import axios from "axios";
import { mask } from "vue-the-mask";
import moment from "moment";
import ProfileHeader from "@/components/Header/ProfileHeader";
import JSZip from "jszip";
import EscortModal from "@/components/Modal/Escort";

export default {
   name: "TenderDetail",
   directives: { mask },
   data() {
      return {
         members: [],
         tender: {},
         id: 0,

         dateEnd: 0,

         status: "error",

         favObjects: [
            // {
            //    id: 4,
            //    name: "Проанализировать",
            // },
            // {
            //    id: 3,
            //    name: "Участвовать",
            // },
            // {
            //    id: 2,
            //    name: "Выиграли",
            // },
            // {
            //    id: 1,
            //    name: "Субподряд",
            // },
         ],

         favBlock: false,
         user: {},
         escrotForm: false,
      };
   },
   computed: {
      memberLot(value) {
         if (value) {
            return "Участвовал";
         } else {
            return "Не участвовал";
         }
      },
   },
   mounted() {
      this.GetMeInfo();
   },
   methods: {
      async getFavorFilters() {
         let { data } = await axios('/api/v1/favorite/statuses')
         let formatData = data.map(item => {
            return {
               id: item.status,
               name: item.status_name
            }
         })
         let start = [
            {
               id: 4,
               name: "Проанализировать",
            },
            {
               id: 3,
               name: "Участвовать",
            },
            {
               id: 2,
               name: "Выиграли",
            },
            {
               id: 1,
               name: "Субподряд",
            },
         ]
         let arr = start.concat(formatData)
         this.favObjects = arr;
         console.log('favor', this.favObjects);
      },
      calculateProcents(item, n) {
         let startPrice = this.tender.Price;
         let secondPrice = item.NMC;
         let temp = (((startPrice - secondPrice) / startPrice) * 100) / n;
         let res = temp.toFixed(2) + "%";
         return res;
      },
      async getMembers() {
         await axios(
            `/api/v1/get/analyze/contragent/${this.tender.CustomerINN}/`
         )
            .then((res) => {
               console.log("customer", res.data);
               this.members = res.data;
               this.members.forEach((item) => {
                  item.procents = this.calculateProcents(
                     item,
                     this.members.length
                  );
               });
            })
            .catch((err) => {
               console.log(err);
            });
      },
      async GetMeInfo() {
         await axios
            .get("/api/v1/me/")
            .then((response) => {
               this.user = response.data;
            })
            .catch((error) => {
               console.log(error);
            });
      },
      CorDate(date) {
         return moment(String(date), "DD-MM-YYYY").format("DD.MM.YYYY");
      },
      GoToRef(val) {
         this.$refs[val].scrollIntoView({ behavior: "smooth" });
      },
      numberWithSpaces(x) {
         return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      favOpen() {
         this.favBlock = !this.favBlock;
      },
      async CheckFavorite() {
         this.id = this.$route.params.id;

         await axios
            .get(`/api/v1/favorite/check/${this.id}/`)
            .then((response) => {
               this.status = response.data.status;

               // if (this.status == 'success') {
               //     this.object_favorite = response.data.body
               // }
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async AddFavorite(list, value) {
         const dataForm = {
            get_tenderID: list.ID,
            user: this.user.user,
            tender: list,
            status: value,
         };

         await axios
            .post("/api/v1/favorite/post/", dataForm)
            .then((response) => {
               this.status = "success";
               this.favBlock = false;
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async UnFav(list_id) {
         await axios
            .delete(`/api/v1/favorite/tender/delete/${list_id}/`)
            .then((response) => {
               this.status = "error";
            })
            .catch((error) => {
               console.log(error);
            });
      },
      async GetTender() {
         this.id = this.$route.params.id;

         await axios
            .get(`/api/v1/tender/detail/${this.id}/`)
            .then((response) => {
               this.tender = response.data.tender;
               this.members = response.data.potential_suppliers;
               var dateB = moment(
                  String(response.data.tender.EndTime),
                  "DD-MM-YYYY"
               );
               var dateC = moment();

               this.dateEnd = dateB.diff(dateC, "days");
               this.dateEnd += 1;
               // this.getMembers();

            })
            .catch((error) => {
               console.log(error);
            });
      },
      // async GetSearch() {
      //      await axios
      //       .get('/api/v1/search/tenders/')
      //       .then(response => {
      //           this.result = response.data

      //           console.log(response.data)
      //       })
      //       .catch(error => {
      //           console.log(error)
      //       })
      //  },
      async downloadAll() {
         // let res = await axios(`/api/v1/tender/detail/docs/${this.id}/`);
         // console.log(res);
         window.location.href = `https://protender.pro/api/v1/tender/detail/docs/${this.id}/`;
      },
   },
   components: {
      "profile-head": ProfileHeader,
      EscortModal
   },
   watch: {},
   created() {
      moment.locale("ru");
      this.getFavorFilters()
      this.GetTender();
      this.CheckFavorite();
   },
};
</script>
